import React from 'react';
import * as Yup from 'yup';
import { Label, Input, Button } from 'cj-common-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import InfoIconText from '../../common-components/InfoIconText';
import { RequiredFieldSymbol } from '../../common-components/RequiredFieldSymbol';
import SimpleFormGroup from '../../common-components/SimpleFormGroup';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import TooltipIconButton from '../../common-components/TooltipIconButton';
import { AUTHENTICATION_TYPES } from '../../../common/constants';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { diff } from 'deep-diff';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');
const config = require('../../../resources/config.json');

const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');
const buttonTexts = getConfigSection(uiTexts, 'merchants.authenticationSettings.authenticationKeys.form.buttons');

const error = getConfigSection(errorsTexts, 'validation');

const authenticationTypeIsNotBasic = authenticationType =>
  authenticationType !== AUTHENTICATION_TYPES.BASIC && authenticationType !== AUTHENTICATION_TYPES.BASIC_AND_OAUTH;

class AuthenticationKeyForm extends React.PureComponent {
  static propTypes = {
    authKeys: PropTypes.array,
    authToken: commonPropTypes.authToken,
    merchantId: PropTypes.string.isRequired,
    isFormEditable: PropTypes.bool.isRequired,
    onAddNewKey: PropTypes.func.isRequired,
    authenticationType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmitForm = this.handleSubmitFormBind.bind(this);
    this.handleCopyToClipboard = this.copyToClipboard.bind(this);
    this.handleCopyButtonMouseLeave = this.onCopyButtonMouseLeave.bind(this);

    this.state = {
      generatedKey: '',
      copyButtonTooltipText: buttonTexts.copyKey,
    };

    this.valuesRef = React.createRef();

    const { authToken } = this.props;

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  compareValues = initialValues => {
    if (!this.valuesRef.current || !this.valuesRef.current.values) {
      return;
    }
    const fVals = this.valuesRef.current.values;

    const diffs = diff(initialValues, fVals);

    return diffs;
  };

  render() {
    const { generatedKey, copyButtonTooltipText } = this.state;
    const { authKeys, authenticationType } = this.props;
    const initialValues = {
      name: '',
    };

    Yup.addMethod(Yup.string, 'validateAuthKeyNameUniqueness', this.validateAuthKeyNameUniqueness);

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .validateAuthKeyNameUniqueness(authKeys, error.authKeyAlreadyExists)
        .required(error.mandatoryField)
        .max(36),
    });

    const formTexts = getConfigSection(uiTexts, 'merchants.authenticationSettings.authenticationKeys.form');

    const gridStyle = {
      display: 'grid',
      gridTemplateColumns: '50px 1fr 1fr',
      gridAutoRows: '80px',
    };

    const testIdPrefix = 'authentication-key-form';

    return (
      <div className="u-mt-large" data-testid={`${testIdPrefix}-container`}>
        <SimpleFormGroup keyName="add-authentication-key">
          <h3 className="u-mb-small" data-testid={`${testIdPrefix}-header`}>
            {formTexts.header}
          </h3>
          <BlockNavigation compareValues={() => this.compareValues(initialValues)} />
          <Formik
            innerRef={f => (this.valuesRef.current = f)}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmitForm}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Label testId={`${testIdPrefix}-name-label`}>
                  <RequiredFieldSymbol />
                  {formTexts.fields.name}
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  testId={`${testIdPrefix}-name-input`}
                />
                <ErrorMessage name="name">
                  {message => (
                    <p className="c-error-message u-mb-xsmall" data-testid={`${testIdPrefix}-name-error-message`}>
                      {message}
                    </p>
                  )}
                </ErrorMessage>

                {authenticationTypeIsNotBasic(authenticationType) && (
                  <InfoIconText text={formTexts.generateKeyInfo} testId="authenticationType-info-text" />
                )}

                <Button
                  className="u-mt-small"
                  secondary
                  type="submit"
                  testId="generate-button"
                  disabled={authenticationTypeIsNotBasic(authenticationType)}
                >
                  {buttonTexts.generate}
                </Button>
              </Form>
            )}
          </Formik>
          {generatedKey && (
            <>
              <div style={gridStyle} className="u-mt-small o-inline-group" data-testid="generated-key-container">
                <Label className="o-inline-group__item" testId="generated-key-label">
                  {formTexts.fields.key}
                </Label>
                <Input
                  className="o-inline-group__item u-mr-none"
                  id="key"
                  type="text"
                  value={generatedKey}
                  disabled
                  testId="generated-key-input"
                />
                <span className="o-inline-group__item" onMouseLeave={this.handleCopyButtonMouseLeave}>
                  <TooltipIconButton
                    tooltipText={copyButtonTooltipText}
                    buttonProps={{
                      className: 'c-table__btn--border',
                      icon: buttonIcons.save,
                      onClick: this.handleCopyToClipboard,
                    }}
                    testId="copy-button"
                  />
                </span>
              </div>
              <InfoIconText text={formTexts.disclaimerText} testId="generated-key-info-text" />
            </>
          )}
        </SimpleFormGroup>
      </div>
    );
  }

  validateAuthKeyNameUniqueness(items, msg) {
    return Yup.string().test({
      name: 'validateAuthKeyNameUniqueness',
      message: msg,
      test: value => !items.some(i => i.name === value),
    });
  }

  async handleSubmitFormBind(values, { resetForm }) {
    const { merchantId, onAddNewKey } = this.props;
    const response = await this.configurationDataApi.addAuthenticationKey(merchantId, {
      name: values.name,
    });

    this.setState({ generatedKey: response.authenticationKey });

    onAddNewKey();
    resetForm();
  }

  copyToClipboard() {
    const { generatedKey } = this.state;
    navigator.clipboard.writeText(generatedKey);

    this.setState({
      copyButtonTooltipText: buttonTexts.keyCopied,
    });
  }

  onCopyButtonMouseLeave() {
    this.setState({
      copyButtonTooltipText: buttonTexts.copyKey,
    });
  }
}

export default connect()(AuthenticationKeyForm);
