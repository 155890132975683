import React from 'react';
import { withRouter } from 'react-router-dom';
import { ModalConfirmOperation } from './ModalWindow';
import uiTexts from '../../resources/uiTexts.json';
import { getConfigSection } from '../../common/utils';

const texts = getConfigSection(uiTexts, 'common.changesModal');

class BlockNavigationLogic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmationModalVisible: false,
      confirmationCb: () => {},
    };
  }

  componentDidMount() {
    this.unblockNavigation = this.blockNavigation();
  }

  componentWillUnmount() {
    this.unblockNavigation();
  }

  commonHandler = cb => {
    const hasChanges = this.props.compareValues();
    if (hasChanges) {
      this.setState({
        ...this.state,
        isConfirmationModalVisible: true,
        confirmationCb: cb,
      });
    } else {
      cb();
    }
  };

  blockNavigation = () => {
    const originalMethods = {
      push: this.props.history.push,
      go: this.props.history.go,
      replace: this.props.history.replace,
      goBack: this.props.history.goBack,
      goForward: this.props.history.goForward,
    };
    this.props.history.push = (...args) => {
      this.commonHandler(() => originalMethods.push(...args));
    };
    this.props.history.go = (...args) => {
      this.commonHandler(() => originalMethods.go(...args));
    };
    this.props.history.replace = (...args) => {
      this.commonHandler(() => originalMethods.replace(...args));
    };
    this.props.history.goBack = (...args) => {
      this.commonHandler(() => originalMethods.goBack(...args));
    };
    this.props.history.goForward = (...args) => {
      this.commonHandler(() => originalMethods.goForward(...args));
    };

    const unblockNavigation = () => {
      this.props.history.push = originalMethods.push;
      this.props.history.go = originalMethods.go;
      this.props.history.replace = originalMethods.replace;
      this.props.history.goBack = originalMethods.goBack;
      this.props.history.goForward = originalMethods.goForward;
    };

    return unblockNavigation;
  };

  renderConfirmationModal = () => {
    if (!this.state.isConfirmationModalVisible) {
      return null;
    }
    return (
      <ModalConfirmOperation
        title={texts.title}
        buttonConfirmText={texts.confirm}
        onConfirm={() => {
          this.state.confirmationCb();
        }}
        buttonCancelText={texts.cancel}
        onCancel={() => {
          this.setState({
            ...this.state,
            isConfirmationModalVisible: false,
          });
        }}
        message={texts.message}
        testId="changes-modal"
      />
    );
  };

  render() {
    return this.renderConfirmationModal();
  }
}

export const BlockNavigation = withRouter(BlockNavigationLogic);
