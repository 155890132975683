import React from 'react';
import { Spinner } from 'cj-common-components';
import AuthenticationSettingsForm from './AuthenticationSettingsForm';
import AuthenticationKeysView from './AuthenticationKeysView';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { AUTHENTICATION_TYPES } from '../../../common/constants';
import { getConfigSection, textsArrayToString } from '../../../common/utils';
import { ModalError } from '../../common-components/ModalWindow';

const uiTexts = require('../../../resources/uiTexts.json');

export default class AuthenticationSettingsPage extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.changeAuthenticationTypeView = this.changeAuthenticationTypeView.bind(this);
    this.handleUpdateAuthenticationSettings = this.handleUpdateAuthenticationSettings.bind(this);

    this.state = {
      authenticationTypeView: '',
      authenticationType: '',
      oauthProviderId: '',
      successMessage: false,
      isLoading: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    const { merchantId } = this.props;
    this.getAuthenticationSettings(merchantId);
  }

  render() {
    const { textsKey, authToken, isFormEditable, merchantId, merchantData } = this.props;

    const { authenticationType, oauthProviderUserId, authenticationTypeView, isLoading } = this.state;

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        {this.state.errorMessage && this.renderErrorDialog()}
        {this.state.successMessage && this.renderSuccessMessage()}
        <AuthenticationSettingsForm
          authToken={authToken}
          merchantId={merchantId}
          changeAuthenticationTypeView={this.changeAuthenticationTypeView}
          authenticationType={authenticationType}
          oauthProviderUserId={oauthProviderUserId}
          submitForm={this.handleUpdateAuthenticationSettings}
          isFormEditable={isFormEditable}
        />

        {(authenticationTypeView === AUTHENTICATION_TYPES.BASIC ||
          authenticationTypeView === AUTHENTICATION_TYPES.BASIC_AND_OAUTH) && (
          <AuthenticationKeysView
            authenticationType={authenticationType}
            authToken={authToken}
            textsKey={textsKey}
            merchantData={{
              ...merchantData,
            }}
            merchantId={merchantId}
            isFormEditable={isFormEditable}
            isAccessLevelApplicable={false}
          />
        )}
      </>
    );
  }

  async getAuthenticationSettings(merchantId) {
    this.setState({ isLoading: true });

    try {
      const authenticationData = await this.configurationDataApi.getAuthenticationSettings(merchantId);

      this.setState({
        authenticationType: authenticationData.authenticationType,
        authenticationTypeView: authenticationData.authenticationType,
        oauthProviderUserId: authenticationData.oauthProviderUserId,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: textsArrayToString(error?.response?.data?.message) || 'common.generalError',
      });
    }
  }

  async handleUpdateAuthenticationSettings(values) {
    const { merchantId } = this.props;

    this.setState({ isLoading: true });

    try {
      const response = await this.configurationDataApi.updateAuthenticationSettings(merchantId, values);

      this.setState({
        ...response,
        successMessage: true,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: textsArrayToString(error?.response?.data?.message) || 'common.generalError',
      });
    }
  }

  renderErrorDialog() {
    const { errorMessage } = this.state;
    return (
      <ModalError
        errorKey={errorMessage}
        onConfirm={() => {
          this.setState({
            errorMessage: '',
          });
        }}
      />
    );
  }

  changeAuthenticationTypeView(newAuthenticationTypeView) {
    this.setState({
      authenticationTypeView: newAuthenticationTypeView,
    });
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}
